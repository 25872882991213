<template>
    <div>
        <v-layout wrap>

            <v-flex md12>
                <v-card>
                    <v-card-title>
                        Create new User
                    </v-card-title>
                    <v-card-text>
                        <v-text-field color="black" v-model="registerModel.username" label="Username"></v-text-field>
                        <v-text-field color="black" v-model="registerModel.email" label="Email"></v-text-field>
                        <v-text-field color="black" v-model="registerModel.password" label="Password"></v-text-field>
                        <v-checkbox label="IsAdmin" v-model="registerModel.isAdmin"></v-checkbox>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" large @click="createUser">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex md12 mt-3>
                <v-card>
                    <v-card-title>
                        Reset Password
                    </v-card-title>
                    <v-card-text>
                        <v-select v-model="resetPassword.username" :items="users" item-text="userName"></v-select>
                        <v-text-field v-model="resetPassword.password"  color="black" label="Password"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" large @click="saveNewPassword">Change</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>

            <v-flex md12 mt-3>
                <v-card>
                    <v-card-title>
                        Change Email
                    </v-card-title>
                    <v-card-text>
                        <v-select v-model="resetEmail.oldEmail" :items="users" item-value="email" item-text="userName"></v-select>
                        <v-text-field v-model="resetEmail.email"  color="black" label="Neue Email"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" large @click="changeEmail">Change</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                registerModel: { username: '', email: '', password: '', isAdmin: false },
                resetPassword: { username: '', password: '' },
                resetEmail: { oldEmail: '', email: '' },
                users: []
            }
        },
        created() {
            this.getUsers()
        },
        methods: {
            getUsers: function () {
                this.$http.get('/api/users/allusers').then(response => {
                    this.users = response.data;
                }).catch((error) => console.log(error));
            },
            createUser: function () {
                this.$http.post('/api/users/CreateUser', this.registerModel).then(response => {
                }).catch((error) => console.log(error));
            },
            saveNewPassword: function () {
                this.$http.post('/api/users/ChangePassword/', this.resetPassword).then(response => {
                    alert("Ok");
                }).catch((error) => console.log(error));
            },
            changeEmail: function () {
                this.$http.post('/api/users/ChangeEmail/', this.resetEmail).then(response => {
                    alert("Ok");
                }).catch((error) => console.log(error));
            },

            //deleteUser: function () {
            //    this.$http.post('/api/users/DeleteUser/' + this.selectedUsername).then(response => {
            //        this.dialogDelete = false;
            //        this.selectedUsername = null;
            //        this.getUsers();
            //    }).catch((error) => console.log(error));

            //},
        }
    }
</script>
<style>
</style>
